import { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { toPixels } from '../util/styles/toPixels';
import { ResponsiveSize } from '../components/image/ImageOptimized';
import { sortedHash } from '../../functions/src/util/hash/sortedHash';
import { assertSafe } from 'functions/src/util/assertSafe';

export const useAvatarBorder = (borderColor?: string) => {
  const theme = useTheme();

  const elevationHashed = useMemo(() => {
    return sortedHash(theme.palette.background.elevation);
  }, [theme.palette.background.elevation]);

  const calculateSize = useCallback(
    (sizeValue: number | string) => {
      const pixelSize = toPixels(sizeValue);
      const isSmall = pixelSize < 32;
      const borderPixels = isSmall ? 1 : 2;
      const borderColorDynamic = isSmall
        ? theme.palette.background.elevation[8]
        : theme.palette.background.elevation[4];
      return `${borderPixels}px solid ${borderColor || borderColorDynamic}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [borderColor, elevationHashed],
  );

  const createResponsiveBorder = useCallback(
    (sizeResponsive: ResponsiveSize) => {
      const borderValues: ResponsiveSize = {};

      // eslint-disable-next-line no-restricted-properties
      for (const [breakpoint, breakpointSize] of Object.entries(
        sizeResponsive,
      )) {
        if (breakpointSize !== undefined) {
          borderValues[assertSafe(breakpoint)] = calculateSize(breakpointSize);
        }
      }

      return {
        border: borderValues,
      } as const;
    },
    [calculateSize],
  );

  const deriveBorderDimensions = useCallback(
    (sizeInput: number | string | ResponsiveSize) => {
      if (typeof sizeInput === 'number' || typeof sizeInput === 'string') {
        return {
          border: calculateSize(sizeInput),
        } as const;
      }
      return createResponsiveBorder(sizeInput);
    },
    [calculateSize, createResponsiveBorder],
  );

  return {
    deriveBorderDimensions,
  } as const;
};
