/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef } from 'react';
import type { Unsubscribe } from 'firebase/firestore';
import { IS_ELECTRON } from '../util/platform';

export const useActiveGameUpdates = () => {
  const unsubscribeRef = useRef<{
    gameInfo?: Unsubscribe;
    gameEvent?: Unsubscribe;
    supportedGames?: Unsubscribe;
  }>({});

  useEffect(() => {
    if (typeof window === 'undefined' || !IS_ELECTRON) {
      return;
    }

    const setupListeners = async () => {
      try {
        const { uploadElectronData } = await import(
          '../firebaseCloud/electron/uploadElectronData'
        );

        unsubscribeRef.current.gameInfo = window.electron?.onGameInfoUpdate(
          async ({ gameId, data }) => {
            const { feature } = data;
            await uploadElectronData({
              gameId: `${gameId}` as const,
              kind: 'info',
              data,
              feature,
            });
          },
        );

        unsubscribeRef.current.gameEvent = window.electron?.onGameEventUpdate(
          async ({ gameId, data }) => {
            const { feature, category } = data;
            await uploadElectronData({
              gameId: `${gameId}`,
              kind: 'event',
              data,
              feature: feature || category,
            });
          },
        );
        //TODO: @shaffy9633 store it somewhere
        // unsubscribeRef.current.supportedGames =
        //   window.electron?.onFetchSupportedGames((data) => {
        //   });
      } catch (e) {
        console.error('Error setting up game update listeners:', e);
      }
    };

    setupListeners();

    return () => {
      // eslint-disable-next-line no-restricted-properties
      for (const unsubscribe of Object.values(unsubscribeRef.current)) {
        unsubscribe?.();
      }
      unsubscribeRef.current = {};
    };
  }, []);
};
