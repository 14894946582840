import { SxProps, useTheme } from '@mui/material/styles';
import { useMemo, ComponentProps } from 'react';
import { memo } from '../../util/memo';
import { StatusBadge } from '../status/StatusBadge';
import { OnlineStatusEnum } from '../../../functions/src/types/realtimeDb/Status';
import { ResponsiveSize } from '../image/ImageOptimized';
import { AvatarNext, AvatarNextProps } from './AvatarNext';

export const DEFAULT_SX = {} as const;

export type AvatarStatusProps = Omit<AvatarNextProps, 'height' | 'width'> & {
  shouldShowStatus?: boolean;
  avatarSx?: AvatarNextProps['sx'];
  badgeSx?: SxProps;
  imgUrl: string;
  onlineStatus: OnlineStatusEnum;
  size?: string | number | ResponsiveSize;
};

const AvatarStatusUnmemoized = ({
  onlineStatus,
  imgUrl,
  size,
  shouldShowStatus = false,
  badgeSx = DEFAULT_SX,
  avatarSx = DEFAULT_SX,
  ...props
}: AvatarStatusProps) => {
  const theme = useTheme();

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const avatar = useMemo(() => {
    return <AvatarNext size={size} src={imgUrl} sx={avatarSx} {...props} />;
  }, [size, imgUrl, avatarSx, props]);

  if (!shouldShowStatus) {
    return avatar;
  }

  return (
    <StatusBadge
      // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
      color={onlineStatus as ComponentProps<typeof StatusBadge>['color']}
      sx={{
        '& .MuiBadge-badge': {
          borderRadius: '50%',
          height: '14px',
          width: '14px',
          border: `2px solid ${theme.palette.background.elevation[6]}`,
          boxShadow: `inset 0 0 0 1px ${theme.palette.text.primary}`,
          ...badgeSx?.['.MuiBadge-badge'],
        },
        ...badgeSx,
      }}
    >
      {avatar}
    </StatusBadge>
  );
};

export const AvatarStatus = memo(AvatarStatusUnmemoized);
