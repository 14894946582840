import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { memo } from '../../../util/memo';
import { useSignOut } from '../../../hooks/auth/useSignOut';
import { ONE_MINUTE_MILLIS } from '../../../../functions/src/util/conversions';
import { getItem, setItem } from '../../../util/webStorage';
import { HttpsError } from '../../../../functions/src/util/errors/HttpsError';
import { sendVerificationEmail } from '../../../util/auth/sendVerificationEmail';
import { ResendEmailButton } from 'src/components/buttons/ResendEmailButton';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { useAuth } from 'src/contexts/AuthContext';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { LottieLoader } from 'src/components/LottieLoader';

export const AUTH_MAIL_SEND_KEY = 'emailSendTime' as const;
export const REFRESH_EMAIL_CHECK_INTERVAL = 5000 as const;

const AuthenticationVerifyingEmailDialogUnmemoized: React.FC = () => {
  const { user } = useAuth();
  const { go, reset } = useWizard();
  const { signOut } = useSignOut();
  const [emailSent, setEmailSent] = useState(() => {
    const sentStartTime = getItem<string>(AUTH_MAIL_SEND_KEY);
    if (!sentStartTime) {
      return false;
    }
    return Date.now() - new Date(sentStartTime).getTime() < ONE_MINUTE_MILLIS;
  });

  const resetAuth = useCallback(async () => {
    await signOut();
    reset();
    go('Sign In Options');
  }, [go, reset, signOut]);

  const sendFirstEmail = useCallback(async () => {
    if (emailSent || !user?.email) {
      return;
    }
    try {
      const { email } = user;
      await sendVerificationEmail(email);
      setEmailSent(true);
      setItem(AUTH_MAIL_SEND_KEY, new Date().toISOString());
      user?.reload?.();
    } catch (error) {
      throw new HttpsError(
        'internal',
        'Failed to send authentication email',
        error,
      );
    }
  }, [emailSent, user]);

  const checkEmailVerification = useCallback(async () => {
    const authImport = import('../../../config/firebase-client/auth');
    const { auth } = await authImport;
    const { currentUser } = auth;
    if (!currentUser) {
      return;
    }
    const { emailVerified } = currentUser;

    await currentUser.reload?.();
    if (emailVerified) {
      go('Verifying Email Success');
    }
  }, [go]);

  useEffect(() => {
    sendFirstEmail();
    const intervalId = setInterval(() => {
      checkEmailVerification();
    }, REFRESH_EMAIL_CHECK_INTERVAL);

    return () => {
      return clearInterval(intervalId);
    };
  }, [go, sendFirstEmail, checkEmailVerification]);

  return (
    <DialogBodyStandard
      description={
        <Stack alignItems="center" maxWidth="440px" pb={2} spacing={4}>
          <LottieLoader sx={{ height: '40px', width: '40px' }} />
          <Typography textAlign="center" variant="body1">
            Please click the link in the email we have just sent to
            <span style={{ paddingLeft: '4px' }}>{user?.email}</span>.
          </Typography>
        </Stack>
      }
      title="Verify Your Email"
    >
      <Stack
        alignSelf="center"
        direction="row"
        maxWidth="440px"
        spacing={4}
        width="100%"
      >
        <ResendEmailButton />
        <LoadingButton
          color="error"
          size="large"
          sx={{ flex: 1 }}
          variant="contained"
          onClick={resetAuth}
        >
          Sign Out
        </LoadingButton>
      </Stack>
    </DialogBodyStandard>
  );
};

export const AuthenticationVerifyingEmailDialog = memo(
  AuthenticationVerifyingEmailDialogUnmemoized,
);
