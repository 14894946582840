import type { Timestamp } from 'firebase-admin/firestore';
import type { DocumentDataEverywhere } from '../../types/DocumentDataEverywhere';
import { isEvmAddress } from '../evmAddresses';
import { Converter } from './Converter';
import { isTimestamp, fromTimestamp, SafeTimestamp } from './timestamp';

export class ConverterFactory {
  public static buildDateConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, Timestamp, Date>(
      isTimestamp,
      (value) => {
        return fromTimestamp(value, (date) => {
          return date;
        });
      },
      false,
    );
  }

  public static buildDateStringConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, Timestamp, string>(
      isTimestamp,
      (value) => {
        return fromTimestamp(value, (date) => {
          return date.toISOString();
        });
      },
      false,
    );
  }

  public static buildTimestampDeserializer<T extends DocumentDataEverywhere>(
    toTimestamp: (value: SafeTimestamp) => Timestamp,
  ) {
    return new Converter<T, SafeTimestamp, Timestamp>(
      isTimestamp,
      (value) => {
        return toTimestamp(value);
      },
      false,
    );
  }

  public static buildEvmAddressConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, string, string>(
      (value: unknown) => {
        return typeof value === 'string' && isEvmAddress(value);
      },
      (value) => {
        return value.toLowerCase();
      },
    );
  }

  public static buildUnixTimeConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, Timestamp, number>(
      isTimestamp,
      (value) => {
        return fromTimestamp(value, (date) => {
          return date.getTime();
        });
      },
      false,
    );
  }
}
