import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import {
  DialogActionsStandard,
  DialogActionsStandardProps,
} from '../../dialog/DialogActionsStandard';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { NoticeParagraphed } from '../../NoticeParagraphed';
import { useErrorAlert } from '../../../hooks/useErrorAlert';
import { RSVP_ERROR_LIBRARY } from '../../../../functions/src/util/error/library/rsvpTeam';
import { useWizard } from 'src/components/wizard/Wizard';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { GradientTypography } from 'src/components/gradients/GradientTypography';

const RulesCheckInDialogUnmemoized = () => {
  const { rulesAndRegulations, date } = useTournamentRegistration();
  const { catchError } = useErrorAlert(RSVP_ERROR_LIBRARY);
  const { checkIn } = useRegistrationFunctions();
  // const { userData } = useAuth();
  const { go } = useWizard();

  const checkInSelf = useCallback(async () => {
    await checkIn(false);
    go('CheckedInDialog');
  }, [checkIn, go]);

  const checkInSelfSafe = useCallback(async () => {
    await catchError(checkInSelf, () => {
      return go(undefined);
    });
  }, [catchError, checkInSelf, go]);

  const format = useDateFormatter({
    separator: ', ',
  });
  const startDateFormatted = format(date);

  const buttons: DialogActionsStandardProps['buttons'] = useMemo(() => {
    return [
      {
        children: 'Accept',
        isAsync: true,
        type: 'submit',
        onClick: checkInSelfSafe,
        endIcon: <CheckCircleOutlineIcon />,
        color: 'secondary',
        sx: { flex: 2 },
      },
      {
        children: 'Cancel',
        isAsync: false,
        onClick: () => {
          return go(undefined);
        },
        color: 'error',
        sx: { textTransform: 'uppercase', flex: 1 },
      },
    ];
  }, [checkInSelfSafe, go]);

  return (
    <DialogBodyStandard
      description={
        <Box>
          <Box
            sx={{
              textAlign: 'center',
              backgroundImage: 'none',
              display: 'inline-block',
              maxWidth: '540px',
            }}
          >
            <Typography sx={{ display: 'inline' }} variant="h6">
              The tournament begins
            </Typography>
            <GradientTypography
              gradientColor="warning.vertical"
              sx={{ display: 'inline', pl: 1 }}
              variant="h6"
            >
              {startDateFormatted}
            </GradientTypography>
            <Typography sx={{ display: 'inline' }} variant="h6">
              . In order to participate, you must agree to the rules below:
            </Typography>
          </Box>

          {!!rulesAndRegulations && (
            <NoticeParagraphed text={rulesAndRegulations} />
          )}
        </Box>
      }
      title={'Tournament Rules'}
    >
      <DialogActionsStandard buttons={buttons} />
    </DialogBodyStandard>
  );
};

export const RulesCheckInDialog = memo(RulesCheckInDialogUnmemoized);
