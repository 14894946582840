import { useCallback } from 'react';
import type { Props as InviteTeamMemberProps } from '../../firebaseCloud/tournament/inviteTeamMember';
import { useErrorAlert } from '../useErrorAlert';
import { findErrorFrom } from '../../util/findErrorFrom';
import { RSVP_ERROR_LIBRARY } from '../../../functions/src/util/error/library/rsvpTeam';

export type UseInviteGuardedProps = {
  onSuccess?: () => Promise<void> | void;
  onError?: (inviteError: {
    title: string;
    message: string;
  }) => Promise<void> | void;
};

export const useInviteGuarded = ({
  onSuccess,
  onError,
}: UseInviteGuardedProps) => {
  const { catchError } = useErrorAlert(RSVP_ERROR_LIBRARY);

  const inviteGuardedUnsafe = useCallback(
    async (params: InviteTeamMemberProps) => {
      const { inviteTeamMember } = await import(
        '../../firebaseCloud/tournament/inviteTeamMember'
      );
      await inviteTeamMember(params);
      await onSuccess?.();
    },
    [onSuccess],
  );

  const inviteGuarded = useCallback(
    async (params: InviteTeamMemberProps) => {
      await catchError(
        async () => {
          await inviteGuardedUnsafe(params);
        },
        async (error) => {
          const { title, message } = findErrorFrom(error, RSVP_ERROR_LIBRARY);
          await onError?.({ title, message });
        },
      );
    },
    [catchError, inviteGuardedUnsafe, onError],
  );

  return inviteGuarded;
};
