import { useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useRouterState } from './routing/useRouterState';
import { useNotificationActions } from './notification/useNotificationActions';
import { useNotificationDialog } from './notification/useNotificationDialog';
import { useDocSnapshot } from './firestore/useDocSnapshot';
import { Notification } from 'functions/src/types/firestore/User/Notification';
import { toNotificationPath } from 'functions/src/types/firestore/User/Notification/path';

export const useNotificationRouter = () => {
  const { uid } = useAuth();
  const [notif, setNotif] = useRouterState({ key: 'notif' });
  const [notifAction, setNotifAction] = useRouterState({ key: 'notif-action' });
  const { executeNotificationAction } = useNotificationActions();
  const { openNotificationDialog } = useNotificationDialog();

  const cleanup = useCallback(
    (data?: Notification<Date>) => {
      if (!data) {
        setNotif(undefined);
        setNotifAction(undefined);
      }
    },
    [setNotif, setNotifAction],
  );
  const notification = useDocSnapshot<Notification<Date>>({
    docPath:
      notif && uid
        ? toNotificationPath({ userId: uid, notificationId: notif })
        : undefined,
    options: {
      includeMetadataChanges: false,
      onSnap: (snap) => {
        return cleanup(snap.data());
      },
    },
  });

  useEffect(() => {
    if (!uid || !notif || notifAction || !notification) {
      return;
    }

    openNotificationDialog(notification);
  }, [uid, notif, notifAction, notification, openNotificationDialog]);

  useEffect(() => {
    if (!uid || !notif || !notification || !notifAction) {
      return;
    }

    let isExecuting = false;

    const executeAction = async () => {
      if (!isExecuting) {
        isExecuting = true;
        try {
          await executeNotificationAction(notification, notifAction);
          cleanup(notification);
        } catch (error) {
          console.error('Failed to execute notification action:', error);
          cleanup(notification);
        }
        isExecuting = false;
      }
    };

    executeAction();

    return () => {
      isExecuting = false;
    };
  }, [
    uid,
    notif,
    notifAction,
    notification,
    executeNotificationAction,
    cleanup,
  ]);

  return { notification };
};
