import { createContext, ReactNode, useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import { useAuth } from '../AuthContext';
import { Match } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match';
import { useCollectionSnapshot } from '../../hooks/firestore/useCollectionSnapshot';
import { useDocSnapshot } from '../../hooks/firestore/useDocSnapshot';
import { Result } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';
import { Team } from '../../../functions/src/types/firestore/Game/Tournament/Team';
import { calculateBestOf } from '../../../functions/src/util/tournaments/match/calculateBestOf';
import { toMatchPath } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/path';
import { useActiveTournament } from './ActiveTournamentContext';

export type ActiveMatchContextProps =
  | {
      activeMatch?: Match<Date>;
      activeResults?: Result[];
      activeSessionIndex: number;
      bestOf: number;
      winner?: Team;
    }
  | undefined;

export const ActiveMatchContext =
  createContext<ActiveMatchContextProps>(undefined);

export const useActiveMatch = () => {
  const context = useContext(ActiveMatchContext);
  if (context === null) {
    throw new Error(
      'useActiveMatch must be used within an ActiveMatchProvider',
    );
  }
  return context;
};

export type ActiveMatchProviderProps = {
  children: ReactNode;
};

export const ActiveMatchProvider = memo(function ActiveMatchProviderUnmemoized({
  children,
}: ActiveMatchProviderProps) {
  const { userData } = useAuth();
  const { tournament } = useActiveTournament();
  const { gameId, id: tournamentId } = tournament || {};
  const { activeTournament } = userData || {};
  const { matchId: activeMatchId, roundId: activeRoundId } =
    activeTournament || {};

  const matchPath = useMemo(() => {
    if (!activeRoundId || !activeMatchId || !gameId || !tournamentId) {
      return;
    }
    return toMatchPath({
      gameId,
      tournamentId,
      roundId: activeRoundId,
      matchId: activeMatchId,
    });
  }, [gameId, activeRoundId, activeMatchId, tournamentId]);
  const matchData = useDocSnapshot<Match<Date>>({
    docPath: matchPath,
  });
  const { resultsAggregation, matchEndCondition } = matchData || {};

  const resultPath = useMemo(() => {
    if (!matchPath) {
      return;
    }
    return `${matchPath}/Result`;
  }, [matchPath]);
  const resultData = useCollectionSnapshot<Result>(resultPath);

  const team1Result = resultData?.[0];
  const team2Result = resultData?.[1];
  const team1 = team1Result?.team;
  const team2 = team2Result?.team;

  const { totalWinsNeeded } = matchEndCondition || {};

  const bestOf = useMemo(() => {
    if (!totalWinsNeeded) {
      return 0;
    }
    return calculateBestOf(totalWinsNeeded);
  }, [totalWinsNeeded]);

  const winner = useMemo(() => {
    if (!team1 || !team2 || !resultsAggregation) {
      return;
    }
    const { winThresholdExceeders } = resultsAggregation;
    if (winThresholdExceeders.length !== 1) {
      return;
    }
    return winThresholdExceeders[0] === team1.id ? team1 : team2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsAggregation, team1?.id, team2, team2Result]);

  const activeSessionIndex = matchData?.activeSessionIndex ?? 0;

  const matchAndResults = useMemo(() => {
    return {
      activeMatch: matchData,
      activeResults: resultData,
      activeSessionIndex,
      bestOf,
      winner,
    } as const;
  }, [matchData, resultData, activeSessionIndex, bestOf, winner]);

  return (
    <ActiveMatchContext.Provider value={matchAndResults}>
      {children}
    </ActiveMatchContext.Provider>
  );
});
//TODO: @shaffy9633: refactor this to useActiveResult. We no longer need activeTournament + activeMatch context, just one activeResult context.
//OR in the future, activeResults, if they are involved in > 1 activeMatch.
