import crypto from 'node:crypto';

export type GenerateInviteTokenParams = {
  inviterId: string;
  teamId: string;
  inviterSecret: string;
};

export const generateInviteToken = ({
  inviterId,
  teamId,
  inviterSecret,
}: GenerateInviteTokenParams) => {
  const hash = crypto.createHash('sha256');
  hash.update(inviterId + teamId + inviterSecret);
  return hash.digest('hex');
};

// import { sha256 } from 'js-sha256';

// export type GenerateInviteTokenParams = {
//   inviterId: string;
//   teamId: string;
//   inviterSecret: string;
//   tournamentId: string;
// };

// export const generateInviteToken = ({
//   inviterId,
//   teamId,
//   inviterSecret,
//   tournamentId,
// }: GenerateInviteTokenParams) => {
//   return sha256(`${inviterId}-${teamId}-${inviterSecret}-${tournamentId}`);
// };
