import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import { ReactNode, useMemo } from 'react';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import { OverlayAvatar } from '../OverlayAvatar';
import { memo } from '../../../util/memo';
import { AvatarGroupNext } from '../../avatar/AvatarGroupNext';
import { IconOverlayAvatar } from '../IconOverlayAvatar';
import { TextOverlayAvatar } from '../TextOverlayAvatar';
import { AvatarGroupUser } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { ChannelGroupType } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { LiveBadge } from '../../LiveBadge';
import { CyclingOverlayAvatar } from '../CyclingOverlayAvatar';

export type ChannelGroupAvatarProps = {
  avatarUsers: AvatarGroupUser[];
  isConnectedToVoice: boolean;
  dateFormatted?: string;
  isSelected: boolean;
  borderColor?: string;
  channelGroupType: ChannelGroupType;
  tournamentId?: string;
  onClick: () => void;
};

const ChannelGroupAvatarUnmemoized = ({
  avatarUsers,
  isConnectedToVoice,
  dateFormatted,
  isSelected,
  borderColor,
  channelGroupType,
  tournamentId,
  onClick,
}: ChannelGroupAvatarProps) => {
  const theme = useTheme();
  const isAvatarGroup = avatarUsers.length >= 2;
  const isTeam = channelGroupType === 'PersonalGroup' && !!tournamentId;
  const isPlayback = channelGroupType === 'Playback';

  const overlayText = useMemo(() => {
    if (isTeam) {
      return 'TEAM';
    }

    return dateFormatted || '-';
  }, [isTeam, dateFormatted]);

  const overlays = useMemo(() => {
    const overlayElements: ReactNode[] = [];

    if (isPlayback) {
      overlayElements.push(
        <OverlayAvatar>
          <LiveBadge sx={{ height: '18px' }} />
        </OverlayAvatar>,
      );
    }

    if (isConnectedToVoice) {
      overlayElements.push(
        <IconOverlayAvatar
          IconComponent={HeadphonesRoundedIcon}
          iconSx={{
            color: theme.palette.secondary.main,
            height: {
              xs: '28px',
              md: '36px',
            },
            width: {
              xs: '28px',
              md: '36px',
            },
          }}
        />,
      );
    }

    if (dateFormatted || isTeam) {
      overlayElements.push(
        <TextOverlayAvatar sx={{ pt: '1px' }} text={overlayText} />,
      );
    }

    return overlayElements;
  }, [
    isPlayback,
    isConnectedToVoice,
    dateFormatted,
    isTeam,
    theme.palette.secondary.main,
    overlayText,
  ]);

  const avatarGroupSx = isAvatarGroup
    ? {
        '.MuiAvatar-root': {
          ml: { xs: '-24px !important', md: '-32px !important' },
          mt: '-16px !important',
          backgroundColor: theme.palette.action.disabled,
          border: `2px solid ${
            theme.palette.background.elevation[assertSafe(isSelected ? 12 : 4)]
          }`,
        },
        '.MuiBadge-root > .MuiAvatar-root': {
          ml: '0px !important',
          mt: '0px !important',
        },
        pt: '12px',
      }
    : {};

  const surplusSx =
    avatarUsers.length <= 9
      ? { pb: '16px', pl: '4px' }
      : { pb: '12px', pl: '4px' };

  return (
    <Box position="relative" onClick={onClick}>
      <AvatarGroupNext
        badgeSx={{
          '.MuiBadge-badge': {
            height: '16px',
            width: '16px',
            border: `2px solid ${theme.palette.background.elevation[4]}`,
          },
        }}
        borderColor={borderColor}
        max={2}
        shouldShowStatus={
          channelGroupType === 'Dm' || channelGroupType === 'PersonalGroup'
        }
        surplusSx={surplusSx}
        sx={avatarGroupSx}
        total={avatarUsers.length}
        users={avatarUsers}
      />
      {overlays.length > 1 ? (
        <CyclingOverlayAvatar overlays={overlays} />
      ) : (
        overlays[0]
      )}
    </Box>
  );
};

export const ChannelGroupAvatar = memo(ChannelGroupAvatarUnmemoized);
