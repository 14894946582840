/* eslint-disable max-lines */
import { useMemo } from 'react';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { useAuth } from '../../contexts/AuthContext';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';
import { useInviteGuarded } from '../friends/useInviteGuarded';
import { useIsUserInGroup } from '../group/useIsUserInGroup';
import { useGuardSignIn } from '../useGuardSignIn';

export type SetTeamNameProps = {
  newName: string;
  teamId: string;
};

export type AcceptTeamInviteProps = {
  details?: Record<string, unknown>;
  teamId?: string;
};

export const useRegistrationFunctions = () => {
  const { uid } = useAuth();
  const { guardSignIn } = useGuardSignIn();

  const {
    gameId,
    id: tournamentId,
    foundTeam,
    roles,
  } = useTournamentRegistration();

  const { checkIsUserInGroup } = useIsUserInGroup();

  const acceptTeamInvite = async ({
    details,
    teamId,
  }: AcceptTeamInviteProps) => {
    if (!uid || !teamId) {
      return;
    }
    const { acceptInvite } = await import(
      '../../firebaseCloud/tournament/acceptInvite'
    );
    await acceptInvite({
      gameId,
      tournamentId,
      teamId,
      details,
    });
  };

  const rejectTeamInvite = async (teamId: string) => {
    const { rejectInvite } = await import(
      '../../firebaseCloud/tournament/rejectInvite'
    );
    await rejectInvite({
      gameId,
      tournamentId,
      teamId,
    });
  };

  const remove = async (memberToRemove: string) => {
    if (!uid || !foundTeam?.name) {
      return;
    }
    const { dropTeamMember } = await import(
      '../../firebaseCloud/tournament/dropTeamMember'
    );

    await dropTeamMember({
      tournamentId,
      teamId: foundTeam.id,
      removeMemberId: memberToRemove,
    });
  };

  const removeGuarded = guardSignIn(remove, 'Sign in to remove team members');

  const leaveTeam = async () => {
    const teamId = foundTeam?.id;
    if (!uid || !teamId) {
      return;
    }
    const { exitTeam } = await import(
      '../../firebaseCloud/tournament/exitTeam'
    );
    await exitTeam({
      teamId,
      tournamentId,
    });
  };

  // eslint-disable-next-line @blumintinc/blumint/semantic-function-prefixes, @blumintinc/blumint/no-unnecessary-verb-suffix
  const checkIn = async (isEntireTeam: boolean, userToCheckIn?: string) => {
    const effectiveUserId = userToCheckIn || uid;
    if (!effectiveUserId) {
      return;
    }

    const { checkIn: checkInTeam } = await import(
      '../../firebaseCloud/tournament/checkIn'
    );
    await checkInTeam({
      isEntireTeam,
      userId: effectiveUserId,
      tournamentId,
      shouldRequireAccepted: true,
    });
  };

  const { registrant } = roles;

  const canRegister = useMemo(() => {
    return (
      registrant &&
      (registrant.some((role) => {
        return checkIsUserInGroup(role);
      }) ||
        registrant.includes('anyone'))
    );
  }, [checkIsUserInGroup, registrant]);

  const registerSolo = async (details: Record<string, unknown>) => {
    if (!uid) return;

    if (!canRegister) {
      throw new HttpsError(
        'permission-denied',
        'You are not allowed to register for this tournament.',
      );
    }

    const { createTeam: createTeamSolo } = await import(
      '../../firebaseCloud/tournament/createTeam'
    );
    await createTeamSolo({
      gameId,
      tournamentId,
      // captainId: uid,
      isSolo: true,
      details,
    });
  };

  const createTeam = async (details: Record<string, unknown>) => {
    if (!uid) return;

    if (!canRegister) {
      throw new HttpsError(
        'permission-denied',
        'You are not allowed to register for this tournament.',
      );
    }

    const { createTeam: createTeamNonSolo } = await import(
      '../../firebaseCloud/tournament/createTeam'
    );
    await createTeamNonSolo({
      // captainId: uid,
      isSolo: false,
      gameId,
      tournamentId,
      details,
    });
  };

  const inviteGuarded = useInviteGuarded({
    onSuccess: () => {
      /* */
    },
  });

  const invite = async (inviteeId: string) => {
    if (!uid || !foundTeam) return;

    await inviteGuarded({
      inviteeId,
      teamId: foundTeam.id,
      gameId,
      tournamentId,
    });
  };

  return {
    remove,
    removeGuarded,
    leaveTeam,
    checkIn,
    registerSolo,
    createTeam,
    invite,
    rejectTeamInvite,
    acceptTeamInvite,
    canRegister,
  } as const;
};
