import { SxProps } from '@mui/material/styles';
import { Fragment, useMemo } from 'react';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { AvatarGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { memo } from '../../util/memo';
import { assertSafe } from '../../../functions/src/util/assertSafe';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';
import { useAvatarBorder } from '../../hooks/useAvatarBorder';
import { AvatarStatus, AvatarStatusProps } from './AvatarStatus';

export const AVATAR_SIZE_PX_LARGE = 56 as const;
export const AVATAR_SIZE_PX_SMALL = 44 as const;
export const AVATAR_SIZE_LARGE_RESPONSIVE = {
  xs: AVATAR_SIZE_PX_SMALL,
  md: AVATAR_SIZE_PX_LARGE,
} as const;

export const AVATAR_SIZE_PX_SECONDARY = 42 as const;
export const AVATAR_SIZE_PX_SECONDARY_SMALL = 30 as const;
export const AVATAR_SIZE_PX_SECONDARY_RESPONSIVE = {
  xs: AVATAR_SIZE_PX_SECONDARY_SMALL,
  md: AVATAR_SIZE_PX_SECONDARY,
} as const;

export type AvatarsProps = Omit<
  AvatarStatusProps,
  'imgUrl' | 'onlineStatus'
> & {
  users: AvatarGroupUser[];
  borderColor?: string;
  badgeSx?: SxProps;
};

export const AvatarsUnmemoized = ({
  users,
  borderColor,
  badgeSx,
  avatarSx,
  shouldShowStatus,
  size = users.length > 1
    ? AVATAR_SIZE_PX_SECONDARY_RESPONSIVE
    : AVATAR_SIZE_LARGE_RESPONSIVE,
  ...rest
}: AvatarsProps) => {
  const userIds = useMemo(() => {
    return users.map(({ userId }) => {
      return userId;
    });
  }, [users]);
  const { deriveBorderDimensions } = useAvatarBorder(borderColor);
  const status = useOnlineStatus(userIds);

  const onlineStatus = useMemo(() => {
    return userIds.some((userId) => {
      return userId && status[assertSafe(userId)];
    })
      ? 'online'
      : 'offline';
  }, [userIds, status]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const avatars = useDeepCompareMemo(() => {
    return users.map(({ userId, imgUrl }, index) => {
      return (
        <AvatarStatus
          key={userId || imgUrl}
          avatarSx={{
            ...deriveBorderDimensions(size),
            ...avatarSx,
          }}
          badgeSx={badgeSx}
          imgUrl={imgUrl}
          onlineStatus={onlineStatus}
          shouldShowStatus={index === 0 && shouldShowStatus}
          size={size}
          {...rest}
        />
      );
    });
  }, [
    users,
    deriveBorderDimensions,
    size,
    avatarSx,
    badgeSx,
    onlineStatus,
    shouldShowStatus,
    rest,
  ]);
  return <Fragment>{avatars}</Fragment>;
};

export const Avatars = memo(AvatarsUnmemoized);
