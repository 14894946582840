import { FC, useCallback, useMemo, useState, Fragment } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { AlertStandard } from '../../AlertStandard';
import {
  DialogActionsStandard,
  DialogActionsStandardProps,
} from '../../dialog/DialogActionsStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { Member } from '../../../../functions/src/types/firestore/Game/Tournament/Team';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { useAuth } from 'src/contexts/AuthContext';
import { useWizard } from 'src/components/wizard/Wizard';
// import { AccordionInfo } from '../../AccordionInfo';
// import {
//   ACCORDION_TITLE_LIVESTREAM,
//   AccordionBodyLivestream,
// } from '../../livestream/AccordionBodyLivestream';

const CheckedInDialogUnmemoized: FC = () => {
  const { foundTeam } = useTournamentRegistration();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { members } = foundTeam!;
  const [isTeamCheckedIn, setIsTeamCheckedIn] = useState(
    members.some((member) => {
      const { isCheckedIn } = member;
      return !isCheckedIn;
    }),
  );
  const { checkIn } = useRegistrationFunctions();
  const { go } = useWizard();
  const { uid } = useAuth();
  const { isCaptain, teammates } = useMemo(() => {
    return members.reduce(
      (acc, member) => {
        const { userId, status } = member;
        if (userId === uid) {
          return { ...acc, isCaptain: status === 'captain' };
        }
        return { ...acc, teammates: [...acc.teammates, member] };
      },
      { isCaptain: false, teammates: [] as Member[] },
    );
  }, [members, uid]);

  const checkInEveryone = useCallback(async () => {
    try {
      //if the user has not updated their username, require them to do so
      // if (!userData?.username || userData?.username?.length > 25) {
      //   go('CreateUsernameDialog');
      //   return;
      // }
      await checkIn(true);
      setIsTeamCheckedIn(true);
    } catch (e) {
      console.error(e);
    } finally {
      go(undefined);
    }
  }, [checkIn, go]);

  const membersToCheckIn = teammates.filter((member) => {
    return member.status === 'accepted' && !member.isCheckedIn;
  });

  const shouldShowDetails = isCaptain && membersToCheckIn.length > 0;

  const buttons: DialogActionsStandardProps['buttons'] = useMemo(() => {
    return [
      {
        children: 'Check-In Everyone',
        type: 'submit',
        isAsync: true,
        onClick: checkInEveryone,
        color: 'secondary',
      },
      {
        children: 'Close',
        isAsync: false,
        onClick: () => {
          return go(undefined);
        },
      },
    ];
  }, [checkInEveryone, go]);

  return (
    <DialogBodyStandard
      description={
        <Stack direction="column" pb={shouldShowDetails ? 0 : 4} spacing={2}>
          <Typography variant="h6">{`You've successfully checked in${
            // eslint-disable-next-line no-restricted-syntax
            isTeamCheckedIn ? ' everyone' : ''
          }!`}</Typography>
          {!!shouldShowDetails && (
            <Fragment>
              <Typography variant="h6">
                Would you like to check in everyone on your team?
              </Typography>
              <AlertStandard
                message={
                  <Typography
                    color={'warning.light'}
                    sx={{
                      fontWeight: 500,
                    }}
                    variant="subtitle1"
                  >
                    If any member on your team does not show up in time for your
                    first match, your entire team will be
                    <span style={{ fontWeight: 700 }}> disqualified</span>.
                  </Typography>
                }
                severity="warning"
              />
            </Fragment>
          )}
          {/* <AccordionInfo
            title={ACCORDION_TITLE_LIVESTREAM}
            body={<AccordionBodyLivestream tournamentTitle={tournamentTitle} />}
            openInDialog={false}
          /> */}
        </Stack>
      }
      title={`${isTeamCheckedIn ? 'Team' : ' '} Check-in Successful!`}
    >
      {!!shouldShowDetails && <DialogActionsStandard buttons={buttons} />}
    </DialogBodyStandard>
  );
};

export const CheckedInDialog = memo(CheckedInDialogUnmemoized);
