export const findCaseInsensitive = (
  string: string,
  array?: string[] | readonly string[],
) => {
  return !!array
    ?.map((item) => {
      return item.toLowerCase();
    })
    .includes(string.toLowerCase());
};
