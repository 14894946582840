/* eslint-disable @blumintinc/blumint/no-unnecessary-verb-suffix */
import { useEffect, useState } from 'react';
import type { Query } from 'firebase/firestore';
import { useDynamic } from '../useDynamic';
import { useAuth } from 'src/contexts/AuthContext';
import { Tournament } from 'functions/src/types/firestore/Game/Tournament';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';

export const useTournamentCheckIn = () => {
  const { uid } = useAuth();
  const [tournamentsToCheckIn, setTournamentsToCheckIn] = useState<
    Tournament<Date>[]
  >([]);

  const firestoreModule = useDynamic(
    import('../../config/firebase-client/firestore'),
  );
  const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

  useEffect(() => {
    if (!uid || !firestoreModule || !firebaseFirestoreModule) {
      return;
    }

    const { firestore } = firestoreModule;
    const { collectionGroup, onSnapshot, query, where } =
      firebaseFirestoreModule;

    const tournamentQuery = query(
      collectionGroup(firestore, 'Tournament') as Query<Tournament>,
      where('phase', '==', 'checkIn'),
    ).withConverter<Tournament<Date>>(ConverterFactory.buildDateConverter());

    const unsubscribe = onSnapshot(
      tournamentQuery,
      (tournamentSnapshots) => {
        const tournaments: Tournament<Date>[] = tournamentSnapshots.docs
          .map((doc) => {
            return doc.data();
          })
          .filter((tournament) => {
            const {
              guestlistAggregated,
              waitlistAggregated,
              skipCheckIn,
              phase,
            } = tournament;
            return (
              [...guestlistAggregated, ...waitlistAggregated].some((team) => {
                const memberToCheckIn = team.members.find((member) => {
                  return member.userId === uid;
                });
                return (
                  team.acceptedUserIds?.includes(uid) &&
                  !memberToCheckIn?.isCheckedIn
                );
              }) &&
              !skipCheckIn &&
              phase === 'checkIn'
            );
          })
          .sort((a, b) => {
            return a.date.getTime() - b.date.getTime();
          });

        setTournamentsToCheckIn(tournaments);
      },
      (error) => {
        console.error(error);
      },
    );

    return () => {
      return unsubscribe();
    };
  }, [uid, firestoreModule, firebaseFirestoreModule]);

  return { tournamentsToCheckIn } as const;
};
