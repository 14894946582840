export const THIRTY_MINS_MILLIS = 1000 * 60 * 30;
export const ONE_HOUR_MILLIS = 1000 * 60 * 60;
export const ONE_DAY_MILLIS = ONE_HOUR_MILLIS * 24;
export const TWO_HOURS_MILLIS = 1000 * 60 * 60 * 2;
export const NINETY_DAYS_MS = ONE_HOUR_MILLIS * 24 * 90;
export const ONE_MINUTE_MILLIS = 1000 * 60;
export const ONE_MINUTE_SECONDS = 60;
export const TWO_HOURS_MINS = 120;
export const ONE_HOURS_MINS = 60;
export const THIRTY_MINS = 30;
export const ZERO_MINS = 0;
export const ONE_SECOND_MILLIS = 1000;
export const TEN_SECONDS_MILLIS = 10 * ONE_SECOND_MILLIS;

export const TIME_UNITS = [
  'milliseconds',
  'seconds',
  'minutes',
  'hours',
] as const;
export type TimeUnit = (typeof TIME_UNITS)[number];

export type ToSecondsParams = {
  maxDuration: number;
  from: TimeUnit;
};

const TIME_UNIT_TO_SECONDS: Record<
  ToSecondsParams['from'],
  (duration: number) => number
> = {
  milliseconds: (duration) => {
    return Math.floor(duration / ONE_SECOND_MILLIS);
  },
  seconds: (duration) => {
    return duration;
  },
  minutes: (duration) => {
    return duration * ONE_MINUTE_SECONDS;
  },
  hours: (duration) => {
    return duration * ONE_MINUTE_SECONDS * ONE_HOURS_MINS;
  },
} as const;

export const toSeconds = ({ maxDuration, from }: ToSecondsParams) => {
  const converter = TIME_UNIT_TO_SECONDS[from];
  if (!converter) {
    throw new Error(`Unsupported time unit: ${from}`);
  }
  return converter(maxDuration);
};
