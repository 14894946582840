import { useMemo } from 'react';
import ScoreboardIcon from '@mui/icons-material/ScoreboardRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import UploadIcon from '@mui/icons-material/UploadRounded';
import { useActiveMatch } from '../../contexts/active-tournament/ActiveMatchContext';
import { isMatchStarted } from '../../../functions/src/util/tournaments/moderation/isMatchStarted';
import { isCurrentSessionScoredBy } from '../../../functions/src/util/tournaments/moderation/isCurrentSessionScoredBy';
import { useAuth } from '../../contexts/AuthContext';
import { isMatchComplete } from '../../../functions/src/util/brackets/bracket2/isMatchComplete';
import { isScreenshotUploaded } from '../../../functions/src/util/tournaments/moderation/isScreenshotUploaded';

export const useScoringButtonDetails = () => {
  const { uid } = useAuth();
  const activeMatchContext = useActiveMatch();
  const {
    activeMatch,
    activeResults,
    activeSessionIndex = 0,
    bestOf = 1,
  } = activeMatchContext || {};
  const { stage } = activeMatch || {};
  const team1Result = activeResults?.[0];
  const team2Result = activeResults?.[1];
  const team1 = team1Result?.team;
  const team2 = team2Result?.team;
  const { scoresMatch: team1Scores = {} } = team1Result || {};
  const { scoresMatch: team2Scores = {} } = team2Result || {};
  const { acceptedUserIds: team1UserIds = [] } = team1 || {};
  const { acceptedUserIds: team2UserIds = [] } = team2 || {};

  const teamUserIds = useMemo(() => {
    const ids = [team1UserIds, team2UserIds];
    return ids;
  }, [team1UserIds, team2UserIds]);
  const currentSessionScored = useMemo(() => {
    return isCurrentSessionScoredBy(
      activeSessionIndex,
      team1Scores,
      team2Scores,
      teamUserIds,
      uid,
    );
  }, [activeSessionIndex, team1Scores, team2Scores, teamUserIds, uid]);

  const screenshots = useMemo(() => {
    return activeResults?.flatMap(({ screenshots: screenshotActive = [] }) => {
      return screenshotActive;
    });
  }, [activeResults]);

  const screenshotUploaded = useMemo(() => {
    return isScreenshotUploaded({
      sessionIndex: activeSessionIndex,
      teamUserIds,
      screenshots,
      uid,
    });
  }, [activeSessionIndex, teamUserIds, screenshots, uid]);

  const session = activeSessionIndex + 1;

  // eslint-disable-next-line no-restricted-properties
  const team1ScoresLength = Object.keys(team1Scores).length;
  // eslint-disable-next-line no-restricted-properties
  const team2ScoresLength = Object.keys(team2Scores).length;

  return useMemo(() => {
    if (
      !isMatchStarted([team1ScoresLength, team2ScoresLength]) ||
      !currentSessionScored
    ) {
      return {
        text: `Score${
          // eslint-disable-next-line no-restricted-syntax
          !!bestOf && bestOf > 1 ? ` ${session} of ${bestOf}` : ''
        }`,
        icon: <ScoreboardIcon />,
      } as const;
    }
    if (!screenshotUploaded) {
      return {
        text: 'Screenshot',
        icon: <UploadIcon />,
      } as const;
    }
    if (!stage || !isMatchComplete({ stage })) {
      return {
        text: 'Re-Score',
        icon: <CheckIcon />,
      } as const;
    }
    return {
      text: 'View Match',
      icon: <ScoreboardIcon />,
    } as const;
  }, [
    team1ScoresLength,
    team2ScoresLength,
    currentSessionScored,
    screenshotUploaded,
    stage,
    bestOf,
    session,
  ]);
};
