import Typography from '@mui/material/Typography';
import { SxProps, useTheme } from '@mui/material/styles';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { useMemo } from 'react';
import { memo } from '../../util/memo';

export type AvatarSurplusProps = {
  surplus: number;
  avatarCount: number;
  sx?: SxProps;
};

const AvatarSurplusUnmemoized = ({
  avatarCount,
  surplus,
  sx,
}: AvatarSurplusProps) => {
  const theme = useTheme();

  const surplusSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.action.active,
    ...sx,
  };

  const surplusDisplayed = useMemo(() => {
    return avatarCount > 9 ? (
      <GroupsRoundedIcon sx={surplusSx} />
    ) : (
      <Typography sx={surplusSx}>{surplus}</Typography>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarCount, surplus, sx]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{surplusDisplayed}</>;
};

export const AvatarSurplus = memo(AvatarSurplusUnmemoized);
