import { useCallback, useMemo } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useFindChannelId } from './useFindChannelId';
import { useFindChannelGroup } from './useFindChannelGroup';

export const useActiveTournamentChannel = (tournamentId?: string) => {
  const { findByTournamentId } = useFindChannelGroup();
  const { findGeneralChannelId, findMatchChannelId } = useFindChannelId();
  const { channelGroupId, switchChannel, openChannelGroup } =
    useActiveChannelGroup();

  const { tournamentChannelGroup, teamChannelGroup } = useMemo(() => {
    return {
      tournamentChannelGroup: findByTournamentId(tournamentId, 'general'),
      teamChannelGroup: findByTournamentId(tournamentId, 'personalGroup'),
    };
  }, [findByTournamentId, tournamentId]);

  const openGeneralChannel = useCallback(() => {
    if (!tournamentId || !tournamentChannelGroup?.id) {
      return;
    }

    const generalChannelId = findGeneralChannelId(tournamentId);

    if (channelGroupId && generalChannelId) {
      return switchChannel(generalChannelId);
    }

    openChannelGroup({
      channelGroupId: tournamentChannelGroup.id,
      channelId: generalChannelId,
    });
  }, [
    findGeneralChannelId,
    openChannelGroup,
    switchChannel,
    tournamentChannelGroup?.id,
    channelGroupId,
    tournamentId,
  ]);

  const openMatchChannel = useCallback(
    (matchId: string) => {
      if (!tournamentId || !tournamentChannelGroup?.id) {
        return;
      }

      const matchChannelId = findMatchChannelId(matchId);
      if (!matchChannelId) {
        return;
      }

      openChannelGroup({
        channelGroupId: tournamentChannelGroup.id,
        channelId: matchChannelId,
      });
    },
    [
      findMatchChannelId,
      openChannelGroup,
      tournamentChannelGroup?.id,
      tournamentId,
    ],
  );

  const openTeamChannel = useCallback(() => {
    if (!teamChannelGroup?.id) {
      return;
    }

    openChannelGroup({ channelGroupId: teamChannelGroup.id });
  }, [openChannelGroup, teamChannelGroup?.id]);

  return {
    openGeneralChannel,
    openMatchChannel,
    openTeamChannel,
    teamChannelGroup,
    tournamentChannelGroup,
  };
};
