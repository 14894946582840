import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';

export const DELAY_MILLIS_DEFAULT = 200 as const;

export const useTextInputDebounce = <TState>(
  initialState: TState | (() => TState),
  delay = DELAY_MILLIS_DEFAULT,
) => {
  const [value, setValue] = useState<TState>(initialState);
  const [debouncedValue, setDebouncedValue] = useState<TState>(initialState);

  const debounce = useDebounceCallback(
    (newValue: TState) => {
      setDebouncedValue(newValue);
    },
    delay,
    false,
  );

  const setValueDebounced: Dispatch<SetStateAction<TState>> = useCallback(
    (newValue) => {
      const resolvedValue =
        typeof newValue === 'function'
          ? (newValue as (prev: TState) => TState)(value)
          : newValue;
      setValue(resolvedValue);
      debounce(resolvedValue);
    },
    [debounce, value],
  );

  return [value, debouncedValue, setValueDebounced] as const;
};
