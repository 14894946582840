import { useCallback, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { memo } from '../../../util/memo';
import { LeaderboardStandard, ROW_SPACING } from '../LeaderboardStandard';
import { useGameResultColumn } from '../../../hooks/leaderboards/useGameResultColumn';
import { useMatchDetails } from '../../../contexts/MatchDetailsContext';
import {
  RESULTS_PAGE_SIZE_DEFAULT,
  useResults,
} from '../../../contexts/ResultsContext';
import { ResultRanked } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';
import { useTeamColumn } from '../../../hooks/leaderboards/useTeamColumn';
import { ScoreOption } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match';
import { LEADERBOARD_STANDARD_SX } from './MatchSummaryTable';
import { RANK_COLUMN } from './RankGridColDef';

export type GameResultsProps = {
  index: number;
};

export const SCORE_OPTION_DEFAULT: ScoreOption = {
  name: 'PTS',
  units: 'pts',
  orderBy: 'desc',
} as const;

export const GameResults = memo(function GameResultsUnmemoized({
  index,
}: GameResultsProps) {
  const { results, page, setPage } = useResults<ResultRanked>();
  const { teamCount } = useMatchDetails();
  const columnsResults = useGameResultColumn({
    scoreOptions: [SCORE_OPTION_DEFAULT],
  });
  const teamColumn = useTeamColumn<ResultRanked>();
  const columns = useMemo(() => {
    const value: GridColDef<ResultRanked>[] = [
      ...(teamCount && teamCount > 2 ? [RANK_COLUMN] : []),
      teamColumn,
      ...columnsResults,
    ];
    return value;
  }, [teamCount, teamColumn, columnsResults]);

  const title = useMemo(() => {
    return `Game ${index + 1}`;
  }, [index]);

  const calculateRowSpacing = useCallback(() => {
    return ROW_SPACING;
  }, []);

  return (
    <LeaderboardStandard
      autoHeight
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      page={page}
      pageSize={RESULTS_PAGE_SIZE_DEFAULT}
      pagination
      paginationMode="server"
      rowCount={teamCount}
      rows={results}
      sx={LEADERBOARD_STANDARD_SX}
      title={title}
      // eslint-disable-next-line react/jsx-sort-props
      onPageChange={setPage}
      getRowSpacing={calculateRowSpacing}
    />
  );
});
