import { useMemo } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { ResultCentralizerContext } from '../../../contexts/wysiwyg/ResultCentralizerContext';
import { useTournamentPermissions } from '../useTournamentPermissions';
import { isUserInTeam } from '../../../../functions/src/util/tournaments/isUserInTeam';
import { isAdmin } from '../../../../functions/src/types/firestore/User/util';

export const useCanScore = () => {
  const { obj } = ResultCentralizerContext.useEntireObject();
  const {
    team,
    tournamentPropagation,
    matchPropagation,
    index = 0,
  } = obj || {};
  const { overwolfGameId } = tournamentPropagation || {};
  const { activeSessionIndex = 0 } = matchPropagation || {};
  const { isTournamentAdmin } = useTournamentPermissions();
  const { acceptedUserIds = [] } = team || {};
  const { uid, user } = useAuth();
  const isBluMintAdmin = isAdmin(user?.email);
  const isOngoingSession = activeSessionIndex === index;
  return useMemo(() => {
    return (
      isBluMintAdmin ||
      (!overwolfGameId &&
        isOngoingSession &&
        (isTournamentAdmin || isUserInTeam(acceptedUserIds, uid)))
    );
  }, [
    isBluMintAdmin,
    overwolfGameId,
    isOngoingSession,
    isTournamentAdmin,
    acceptedUserIds,
    uid,
  ]);
};
