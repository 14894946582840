import { useRef, useState } from 'react';
import { useWizardDialog } from '../../../hooks/wizard/useWizardDialog';
import { TournamentRegistrationProvider } from '../../../contexts/TournamentRegistrationContext';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { AuthSubmitProvider } from '../../../contexts/AuthSubmitContext';
import { EntryDialog } from 'src/components/tournaments/check-in/EntryDialog';
import { CheckedInDialog } from 'src/components/tournaments/check-in/CheckedInDialog';
import { CustomizeUsernameDialog } from 'src/components/tournaments/check-in/CustomizeUsernameDialog';
import { RulesCheckInDialog } from 'src/components/tournaments/check-in/RulesCheckInDialog';
import { UnregisterGuardDialog } from 'src/components/tournaments/check-in/UnregisterGuardDialog';
import { UnregisteredDialog } from 'src/components/tournaments/check-in/UnregisteredDialog';

export type CheckInStore = {
  didAcceptRules: boolean;
};

export function useCheckInDialog() {
  const { open: openWizardDialog, close, isOpen } = useWizardDialog();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [hasClosed, setHasClosed] = useState(false);

  const open = (tournament: Tournament<Date>, onClose?: () => void) => {
    const storeDefault: CheckInStore = {
      didAcceptRules: false,
    };
    const { phase, readyTime, checkInTime } = tournament;
    if (Date.now() >= readyTime.getTime()) {
      return;
    }
    const timeRef = Math.max(Date.now(), checkInTime.getTime());
    const checkInWindowTime = Math.abs(readyTime.getTime() - timeRef);
    if (phase === 'checkIn' && !hasClosed) {
      openWizardDialog({
        storeDefault,
        elements: {
          EntryDialog: <EntryDialog />,
          CreateUsernameDialog: (
            <AuthSubmitProvider>
              <CustomizeUsernameDialog />
            </AuthSubmitProvider>
          ),
          RulesCheckInDialog: <RulesCheckInDialog />,
          CheckedInDialog: <CheckedInDialog />,
          UnregisterGuardDialog: <UnregisterGuardDialog />,
          UnregisteredDialog: <UnregisteredDialog />,
        },
        elementIdEntry: 'EntryDialog',
        Wrapper: <TournamentRegistrationProvider tournament={tournament} />,
        wrapperProps: {
          shouldPreventBackdropClick: true,
        },
        onClose,
      });

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // TODO: we will be getting rid of check in flow in favor of notifications
      // eslint-disable-next-line no-restricted-globals
      timerRef.current = setTimeout(() => {
        close();
        setHasClosed(true);
      }, checkInWindowTime);
    }
  };
  return { open, close, isOpen } as const;
}
