/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { toNotificationPath } from '../../../functions/src/types/firestore/User/Notification/path';
import { useDocUpdate } from '../firestore/useDocUpdate';
import { Notification } from '../../../functions/src/types/firestore/User/Notification';

export type NotificationUpdateParams = {
  toId?: string;
  id?: string;
};

export type NotificationUpdates = {
  markAsRead: () => Promise<void>;
  markAsArchived: () => Promise<void>;
};

export function useUpdateNotification(data: NotificationUpdateParams) {
  const { toId, id } = data;
  const path =
    toId && id
      ? toNotificationPath({ userId: toId, notificationId: id })
      : undefined;
  const updateDoc = useDocUpdate<Notification>(path);

  const markAsRead = useCallback(async () => {
    await updateDoc({ status: 'read' });
  }, [updateDoc]);

  const markAsArchived = useCallback(async () => {
    await updateDoc({ status: 'archived' });
  }, [updateDoc]);

  return { markAsRead, markAsArchived };
}
