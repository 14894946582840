import { RULES_TRANSLATIONS } from '../../../functions/src/types/country';
import { TournamentDetail } from '../../../functions/src/types/firestore/Game/Tournament';

export const extractTournamentRules = (
  tournamentDetails: TournamentDetail[],
) => {
  const detail = tournamentDetails?.find((tournamentDetail) => {
    const { title } = tournamentDetail;
    return RULES_TRANSLATIONS.some((rule) => {
      return title.toLowerCase().includes(rule);
    });
  });
  return detail ? detail.body : undefined;
};
