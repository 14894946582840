import Stack, { StackProps } from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { Link } from '../../Link';
import { User } from '../../../../functions/src/types/firestore/User';
import { GroupInfoBasic } from '../../../../functions/src/types/firestore/Guild';
import { AvatarNext, AvatarNextProps } from '../../avatar/AvatarNext';
import { UsernameStatus, UsernameStatusProps } from './UsernameStatus';

const LINK_STYLE = {
  textDecoration: 'none',
  flex: 1,
} as const;

export type UsernameAvatarProps = Pick<User, 'status'> &
  Pick<GroupInfoBasic, 'username' | 'imgUrl' | 'id'> & {
    avatarProps?: Omit<AvatarNextProps, 'src' | 'alt'>;
    usernameProps?: Omit<UsernameStatusProps, 'username' | 'status'>;
    isReversed?: boolean;
    wrapperProps?: StackProps;
  };

function UsernameAvatarUnmemoized({
  avatarProps,
  usernameProps,
  id,
  imgUrl,
  username,
  status,
  isReversed,
  wrapperProps,
}: UsernameAvatarProps) {
  return (
    <Link href={`/${id}`} style={LINK_STYLE}>
      <Stack
        alignItems="center"
        direction={isReversed ? 'row-reverse' : 'row'}
        spacing={2}
        {...wrapperProps}
      >
        <AvatarNext {...avatarProps} alt={username} src={imgUrl} />
        <UsernameStatus
          status={status}
          username={username}
          {...usernameProps}
        />
      </Stack>
    </Link>
  );
}

export const UsernameAvatar = memo(UsernameAvatarUnmemoized);
