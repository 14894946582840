import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { findCaseInsensitive } from '../../../functions/src/util/findCaseInsensitive';
import { toUserId } from '../../../functions/src/util/messaging/mapId';
import {
  DynamicModule,
  StreamChatReact,
} from '../../../functions/src/types/DynamicModule';

export type UseIsUserInChannelProps = {
  streamChatModule: DynamicModule[StreamChatReact];
};

export const useIsUserInChannel = ({
  streamChatModule,
}: UseIsUserInChannelProps) => {
  const { uid } = useAuth();

  const { useChatContext } = streamChatModule;
  const { channel } = useChatContext();

  const { state } = channel || {};
  const { members } = state || {};

  return useMemo(() => {
    if (!members || !uid) {
      return false;
    }
    // eslint-disable-next-line no-restricted-properties
    const userIdsMembers = Object.keys(members);
    const userIdsFormatted = userIdsMembers.map((id) => {
      return toUserId(id);
    });
    return !!findCaseInsensitive(uid, userIdsFormatted);
  }, [members, uid]);
};
