import { createPortal } from 'react-dom';
import { useMemo } from 'react';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';
import { useQuerySelector } from '../../hooks/useQuerySelector';
import { memo } from '../../util/memo';
import { Livestream } from './Livestream';

export const THEATER_MODE_PLAYER_ID = 'theater-mode-player' as const;
export const MINI_PLAYER_ID = 'mini-player' as const;
export const PREVIEW_PLAYER_ID = 'preview' as const;

export const GlobalVideoPlayerUnmemoized = () => {
  const { isTheaterMode, isMiniPlayerMode, isPreviewPlayerMode } =
    useLivestreamMode();

  const currentMode = useMemo(() => {
    if (isTheaterMode) return { id: THEATER_MODE_PLAYER_ID } as const;
    if (isMiniPlayerMode) return { id: MINI_PLAYER_ID } as const;
    if (isPreviewPlayerMode) return { id: PREVIEW_PLAYER_ID } as const;
  }, [isTheaterMode, isMiniPlayerMode, isPreviewPlayerMode]);

  const portalRoot = useQuerySelector<HTMLElement>(`#${currentMode?.id}`, {
    root: currentMode ? document.body : 'suspend-observation',
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    observeOptions: { childList: true },
    debounceMs: 100,
  });

  if (!currentMode || !portalRoot) {
    return null;
  }

  return createPortal(<Livestream />, portalRoot);
};

export const GlobalVideoPlayer = memo(GlobalVideoPlayerUnmemoized);
