import Button, { ButtonProps } from '@mui/material/Button';
import { Fragment, useCallback, useMemo } from 'react';
import ScoreboardIcon from '@mui/icons-material/ScoreboardRounded';
import { memo } from '../../../util/memo';
import { useActiveMatch } from '../../../contexts/active-tournament/ActiveMatchContext';
import { useActiveTournament } from '../../../contexts/active-tournament/ActiveTournamentContext';
import { useMatchDetailsRouter } from '../../../hooks/tournaments/useMatchDetailsRouter';
import { isMatchReady } from '../../../../functions/src/util/tournaments/moderation/isMatchReady';
import { useIsUserInMatchChat } from '../../../hooks/messaging/useIsUserInMatchChat';
import { isUserInvolved } from '../../../../functions/src/util/tournaments/match/isUserInvolved';
import { useAuth } from '../../../contexts/AuthContext';
import { isTournamentAdmin } from '../../../../functions/src/util/tournaments/isTournamentAdmin';
import {
  STREAM_CHAT_REACT,
  StreamChatReact,
  DynamicImport,
} from '../../../../functions/src/types/DynamicModule';
import { withDynamicImport } from '../../../util/withDynamicImport';
import { assertSafe } from 'functions/src/util/assertSafe';

export type ActiveMatchButtonBaseProps = Omit<
  ButtonProps,
  'onClick' | 'variant'
> & {
  modules: DynamicImport<[StreamChatReact]>;
};

const ActiveMatchButtonBaseUnmemoized = ({
  children = 'View Match',
  startIcon,
  modules,
  ...rest
}: ActiveMatchButtonBaseProps) => {
  const { uid } = useAuth();
  const { tournament } = useActiveTournament();

  const { roles } = tournament || {};

  const streamChatModule = modules[assertSafe(STREAM_CHAT_REACT)];
  const isCorrectChat = useIsUserInMatchChat({
    streamChatModule,
  });
  const activeMatchContext = useActiveMatch();
  const { activeMatch, activeResults } = activeMatchContext || {};
  const { id: activeMatchId, activeSessionIndex = 0 } = activeMatch || {};
  const team1Result = activeResults?.[0];
  const team2Result = activeResults?.[1];
  const team1 = team1Result?.team;
  const team2 = team2Result?.team;
  const [_activeMatchRouter, setActiveMatchRouter] = useMatchDetailsRouter({
    matchIdRouter: activeMatchId,
    tournament,
  });

  const openMatch = useCallback(() => {
    if (!activeMatchId) {
      return;
    }
    setActiveMatchRouter({
      matchId: activeMatchId,
      sessionIndex: activeSessionIndex,
    });
  }, [activeMatchId, setActiveMatchRouter, activeSessionIndex]);

  const canShowScoring = useMemo(() => {
    const acceptedUserIdsTeams = [team1, team2].flatMap((team) => {
      const { acceptedUserIds = [] } = team || {};
      return acceptedUserIds;
    });

    return (
      isCorrectChat &&
      isMatchReady({ team1, team2 }) &&
      (isUserInvolved(acceptedUserIdsTeams, uid) ||
        isTournamentAdmin({ roles, uid }))
    );
  }, [isCorrectChat, roles, team1, team2, uid]);

  const buttonBase = useMemo(() => {
    return (
      !!canShowScoring && (
        <Button
          startIcon={startIcon || <ScoreboardIcon />}
          sx={{ borderRadius: '50px', height: '30px' }}
          onClick={openMatch}
          {...rest}
        >
          {children}
        </Button>
      )
    );
  }, [canShowScoring, openMatch, startIcon, rest, children]);

  return <Fragment>{buttonBase}</Fragment>;
};

export const ActiveMatchButtonBase = memo(
  withDynamicImport({
    Component: ActiveMatchButtonBaseUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
