import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { useCallback, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { ActiveMatchButton } from '../active-match/ActiveMatchButton';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { useChannelList } from '../../../contexts/ChannelListContext';
import { Viewers } from '../../live-viewers/Viewers';
import {
  DynamicImport,
  STREAM_CHAT_REACT,
  StreamChatReact,
} from '../../../../functions/src/types/DynamicModule';
import { withDynamicImport } from '../../../util/withDynamicImport';
import {
  ChannelHeaderTitle,
  CHANNEL_HEADER_HEIGHT,
} from './ChannelHeaderTitle';
import { assertSafe } from 'functions/src/util/assertSafe';

const SX_OUTER = { p: 0 } as const;

export type ChannelHeaderProps = {
  children?: ReactNode;
  modules: DynamicImport<[StreamChatReact]>;
};

const ChannelHeaderUnmemoized = ({ children, modules }: ChannelHeaderProps) => {
  const theme = useTheme();

  const { closeChannel } = useActiveChannelGroup();
  const { isChannelListVisible, showChannelList } = useChannelList();

  const goBack = useCallback(() => {
    closeChannel();
    showChannelList();
  }, [showChannelList, closeChannel]);

  const { useChatContext } = modules[assertSafe(STREAM_CHAT_REACT)];
  const { channel } = useChatContext();
  const channelId = channel?.cid;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        background: theme.palette.background.elevation['0'],
        px: 2,
        zIndex: 9,
        height: {
          xs: isChannelListVisible ? '36px' : '68px',
          md: `${CHANNEL_HEADER_HEIGHT}px`,
        },
        borderBottom: `0.5px solid ${theme.palette.action.disabled}`,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        width="100%"
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
          sx={{ background: theme.palette.background.elevation['0'] }}
        >
          {!isChannelListVisible && (
            <GradientIconButton
              gradientColor="primary.horizontal"
              IconComponent={ArrowBackIcon}
              sx={{
                height: '24px',
                width: '24px',
              }}
              sxOuter={SX_OUTER}
              onClick={goBack}
            />
          )}
          <ChannelHeaderTitle />
        </Stack>
        {!!channelId && <Viewers elementId={channelId} />}
        <ActiveMatchButton />
      </Stack>
      {children}
    </Stack>
  );
};

export const ChannelHeader = memo(
  withDynamicImport({
    Component: ChannelHeaderUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
