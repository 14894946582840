import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { UsernameAvatar, UsernameAvatarProps } from './UsernameAvatar';

export type UserCardLayoutProps = {
  sx?: SxProps;
  children?: ReactNode;
} & UsernameAvatarProps;

const UserCardLayoutUnmemoized = ({
  children,
  sx,
  ...usernameAvatarProps
}: UserCardLayoutProps) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={2}
      sx={{
        borderRadius: '10px',
        px: 2,
        py: 1,
        ...sx,
      }}
    >
      <UsernameAvatar {...usernameAvatarProps} />
      {children}
    </Stack>
  );
};

export const UserCardLayout = memo(UserCardLayoutUnmemoized);
