import Typography from '@mui/material/Typography';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactNode, useMemo } from 'react';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { SxProps, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { GradientTypography } from '../gradients/GradientTypography';
import { GradientIcon } from '../gradients/GradientIcon';

export const MAX_TOURNAMENT_PANEL_WIDTH = '970px' as const;

export type TournamentPanelV3Props = {
  TitleIconComponent?: OverridableComponent<SvgIconTypeMap>;
  title?: string | ReactNode;
  description?: string | ReactNode;
  Content: ReactNode;
  wrapperSx?: SxProps;
};

export const TournamentPanelV3 = memo(function TournamentPanelV3Unmemoized({
  TitleIconComponent,
  title,
  description,
  Content,
  wrapperSx,
}: TournamentPanelV3Props) {
  const theme = useTheme();

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const titleComponent = useMemo(() => {
    if (!title) return null;
    if (typeof title === 'string') {
      return (
        <GradientTypography
          fontWeight={600}
          gradientColor="primary.vertical"
          textTransform="uppercase"
          variant="h6"
        >
          {title}
        </GradientTypography>
      );
    }
    return title;
  }, [title]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const descriptionComponent = useMemo(() => {
    if (!description) return null;
    if (typeof description === 'string') {
      return (
        <Typography color="text.secondary" sx={{ py: 1 }} variant="body1">
          {description}
        </Typography>
      );
    }
    return description;
  }, [description]);

  return (
    <Stack spacing={2} sx={{ ...theme.panels[1], ...wrapperSx }} width={'100%'}>
      <Stack
        alignItems="center"
        direction="row"
        gap={2}
        justifyContent="flex-start"
      >
        {!!TitleIconComponent && (
          <GradientIcon
            gradientColor="primary.horizontal"
            IconComponent={TitleIconComponent}
            sx={{ width: 24, height: 24 }}
          />
        )}
        {titleComponent}
      </Stack>
      {descriptionComponent}
      {Content}
    </Stack>
  );
});
