import {
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  ForwardedRef,
  useRef,
} from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { EditProps } from '../EditProps';
import { useTextInputDebounce } from '../../../hooks/useTextInputDebounce';
import { useErrorValidation } from '../../../hooks/edit/useErrorValidation';
import { memo } from '../../../util/memo';

export type TextFieldPropsRest = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'autoFocus' | 'error' | 'helperText'
>;

export type SelectPropsRest = Omit<
  SelectProps,
  'value' | 'onChange' | 'autoFocus'
>;

export type TextFieldEditProps<TValue> = EditProps<TValue, string> &
  Omit<TextFieldPropsRest & SelectPropsRest, keyof EditProps<TValue, string>>;

function TextFieldEditReflessUnmemoized<TValue>(
  {
    // eslint-disable-next-line no-restricted-syntax
    value = '' as TValue,
    onChange,
    options,
    onError,
    ...rest
  }: TextFieldEditProps<TValue>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [valueUnvalidated, valueUnvalidatedDebounced, setValueUnvalidated] =
    useTextInputDebounce<string>(String(value));
  const pendingEnterEventRef = useRef<React.KeyboardEvent | null>(null);

  useEffect(() => {
    setValueUnvalidated(String(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onValidationComplete = useCallback((isValid: boolean) => {
    if (isValid && pendingEnterEventRef.current?.key === 'Enter') {
      const enterEvent = new KeyboardEvent('keydown', {
        key: 'Enter',
        code: 'Enter',
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        bubbles: true,
        cancelable: true,
      });
      pendingEnterEventRef.current.target?.dispatchEvent(enterEvent);
    }
    pendingEnterEventRef.current = null;
  }, []);

  const { error, validateAndChange } = useErrorValidation({
    value,
    onChange,
    options,
    onError,
    setValueUnvalidated,
    valueUnvalidated: valueUnvalidatedDebounced,
    onValidationComplete,
  });

  const selectOptions = useMemo(() => {
    if (!Array.isArray(options)) {
      return null;
    }
    return options.map(String).map((option) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    });
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [options]);

  const changeSelect = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setValueUnvalidated(e.target.value as string);
    },
    [setValueUnvalidated],
  );

  const changeTextField = useCallback(
    (e: { target: { value: string } }) => {
      setValueUnvalidated(e.target.value);
    },
    [setValueUnvalidated],
  );

  const captureEnter = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        pendingEnterEventRef.current = e;
        validateAndChange(valueUnvalidated);
      }
    },
    [validateAndChange, valueUnvalidated],
  );

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const select = useMemo(() => {
    if (!selectOptions) {
      return null;
    }
    const { sx = {}, ...selectProps } = rest as SelectPropsRest;
    return (
      <Select
        ref={ref}
        autoFocus
        fullWidth
        size="small"
        value={valueUnvalidated}
        onChange={changeSelect}
        // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns, @typescript-eslint/no-explicit-any
        {...(selectProps as any)}
        // We have to make sure there's space under the select for any validation error messages
        // eslint-disable-next-line @blumintinc/blumint/no-margin-properties
        sx={{ mb: 4, ...sx }}
      >
        {selectOptions}
      </Select>
    );
  }, [rest, selectOptions, valueUnvalidated, changeSelect, ref]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const textField = useMemo(() => {
    const { sx = {}, ...textFieldProps } = rest as TextFieldPropsRest;
    return (
      <TextField
        ref={ref}
        autoFocus
        error={!!error}
        fullWidth
        helperText={error || ' '}
        multiline={false}
        size="small"
        value={valueUnvalidated}
        variant="outlined"
        onChange={changeTextField}
        onKeyDown={captureEnter}
        {...textFieldProps}
        sx={sx}
      />
    );
  }, [rest, ref, error, valueUnvalidated, changeTextField, captureEnter]);

  return select || textField;
}

// eslint-disable-next-line @blumintinc/blumint/global-const-style
const TextFieldEditUnmemoized = forwardRef<
  HTMLDivElement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TextFieldEditProps<any>
>(TextFieldEditReflessUnmemoized) as typeof TextFieldEditReflessUnmemoized;

export const TextFieldEdit = memo(
  TextFieldEditUnmemoized,
) as typeof TextFieldEditUnmemoized;
