import { useState, useEffect, useCallback } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { memo } from '../../util/memo';
import { sendVerificationEmail } from '../../util/auth/sendVerificationEmail';
import { setItem } from '../../util/webStorage';
import { AUTH_MAIL_SEND_KEY } from '../authentication/flow-dialogs/AuthenticationVerifyingEmailDialog';
import { LoadingButton } from './LoadingButton';
import { useAuth } from 'src/contexts/AuthContext';

export const ResendEmailButton = memo(function ResendEmailButtonUnmemoized() {
  const { user } = useAuth();
  const [resent, setResent] = useState(false);

  const [seconds, setSeconds] = useState<number | null>(60);
  const [isCountingDown, setIsCountingDown] = useState(true);

  const startCountdown = useCallback(() => {
    setSeconds(60);
    setIsCountingDown(true);
  }, []);

  useEffect(() => {
    if (seconds === null) return;

    if (seconds === 0) {
      setIsCountingDown(false);
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        return prevSeconds === null ? null : prevSeconds - 1;
      });
    }, 1000);

    return () => {
      return clearInterval(interval);
    };
  }, [seconds]);

  const resendEmail = useCallback(async () => {
    if (!user?.email || user?.emailVerified) {
      return;
    }
    try {
      await sendVerificationEmail(user.email);
      setItem(AUTH_MAIL_SEND_KEY, new Date().toISOString());
      setResent(true);
    } catch (e) {
      console.error(e);
    }
  }, [user?.email, user?.emailVerified]);

  const resendAndCountdown = useCallback(async () => {
    await resendEmail();
    startCountdown();
  }, [resendEmail, startCountdown]);

  return (
    <LoadingButton
      disabled={isCountingDown}
      endIcon={resent ? <CheckCircleOutlineRoundedIcon /> : undefined}
      fullWidth
      size="large"
      sx={{ flex: 2, justifyContent: 'center' }}
      variant="contained"
      onClick={resendAndCountdown}
    >
      Resend Email
      {!!isCountingDown && <span>: {seconds}</span>}
    </LoadingButton>
  );
});
