/* eslint-disable max-lines */
import {
  useCallback,
  useMemo,
  ComponentType,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { Optional } from 'utility-types';
import {
  withEditLinkMenu,
  WithEditLinkMenuProps,
} from '../link/withEditLinkMenu';
import { EditProps } from '../EditProps';
import { compareDeeply, memo } from '../../../util/memo';
import { withOnDefocus } from '../withOnDefocus';
import { LoadingWrapperProvider } from '../../../contexts/LoadingWrapperContext';
import { useMeasureViewSize } from '../../../hooks/useMeasureViewSize';
import { EditLoadingOverlay } from './EditLoadingOverlay';
import {
  EditableWrapperProps,
  ViewComponentProps,
} from './EditableWrapperProps';

const EDITABLE_CURSOR_SX = {
  // Target all input elements and other editable elements within the wrapper
  '& input, & textarea, & .MuiInputBase-input, & .MuiSelect-select, & .MuiAutocomplete-input':
    {
      cursor: 'pointer',
    },
} as const;

const NON_EDITABLE_CURSOR_SX = {
  '& input, & textarea, & .MuiInputBase-input, & .MuiSelect-select, & .MuiAutocomplete-input':
    {
      cursor: 'default',
    },
} as const;

const MIN_SIZE_STYLE = { minHeight: '28px', minWidth: '32px' } as const;

export type EditableWrapperBaseProps<
  TValue,
  TValueBase = TValue,
  TUrl extends string = string,
> = Optional<
  Omit<EditableWrapperProps<TValue, TValueBase, TUrl>, 'link'>,
  'onChangeUrl'
> & {
  /**
   * EditComponent must forward ref
   */
  EditComponent: ComponentType<EditProps<TValue, TValueBase>>;
  link?: Omit<WithEditLinkMenuProps<TUrl>['link'], 'menuItemsOther'>;
};

function EditableWrapperBaseUnmemoized<
  TValue,
  TValueBase = TValue,
  TUrl extends string = string,
>({
  value,
  onChange,
  url,
  onChangeUrl,
  options,
  ViewComponent,
  EditComponent,
  isEditing,
  link,
  overlaySx,
}: EditableWrapperBaseProps<TValue, TValueBase, TUrl>) {
  const [isEditingInternal, setIsEditingInternal] = useState(isEditing);

  const { ref: viewComponentRef, viewSize, measure } = useMeasureViewSize();

  useLayoutEffect(() => {
    measure();
  }, [value, measure]);

  // eslint-disable-next-line @blumintinc/blumint/prefer-usememo-over-useeffect-usestate
  useEffect(() => {
    setIsEditingInternal(!!isEditing);
  }, [isEditing]);

  const canEdit = onChange !== 'disabled';

  const startEditing = useCallback(() => {
    if (canEdit) {
      measure();
      setIsEditingInternal(true);
    }
  }, [canEdit, measure]);

  const editMenuItem = useMemo(() => {
    if (!canEdit) return null;

    return (
      <MenuItem onClick={startEditing}>
        <ListItemText primary="EDIT VALUE" />
      </MenuItem>
    );
  }, [startEditing, canEdit]);

  const ViewLinkMenu = withEditLinkMenu<ViewComponentProps<TValue>, TUrl>(
    ViewComponent,
  );

  const hasLinkFunctionality = url || onChangeUrl;

  const linkProps = useMemo(() => {
    return {
      ...link,
      menuItemsOther: editMenuItem ? [editMenuItem] : [],
    };
  }, [link, editMenuItem]);

  const cursorSx = canEdit ? EDITABLE_CURSOR_SX : NON_EDITABLE_CURSOR_SX;

  const mergedOverlaySx = useMemo(() => {
    return { ...cursorSx, ...overlaySx };
  }, [cursorSx, overlaySx]);

  const WrappedViewComponent = useCallback(
    (props: ViewComponentProps<TValue>) => {
      if (!hasLinkFunctionality) {
        return canEdit ? (
          <Box
            ref={viewComponentRef}
            style={MIN_SIZE_STYLE}
            sx={cursorSx}
            onClick={startEditing}
          >
            <ViewComponent {...props} />
          </Box>
        ) : (
          <Box ref={viewComponentRef} sx={cursorSx}>
            <ViewComponent {...props} />
          </Box>
        );
      }

      return (
        <Box ref={viewComponentRef} sx={cursorSx}>
          <ViewLinkMenu
            {...props}
            link={linkProps}
            url={url}
            // onChangeUrl is defined if hasLinkFunctionality
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onChangeUrl={onChangeUrl!}
          />
        </Box>
      );
    },
    [
      hasLinkFunctionality,
      url,
      ViewLinkMenu,
      onChangeUrl,
      linkProps,
      startEditing,
      ViewComponent,
      canEdit,
      cursorSx,
      viewComponentRef,
    ],
  );

  const viewComponent = useMemo(() => {
    return (
      <EditLoadingOverlay
        disabled={!canEdit}
        isAlwaysShowingIcon={!value}
        sx={mergedOverlaySx}
      >
        <WrappedViewComponent value={value} />
      </EditLoadingOverlay>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WrappedViewComponent, value, mergedOverlaySx, canEdit]);

  const toggleOffEditing = useCallback(() => {
    setIsEditingInternal(false);
  }, []);

  const DefocusableEditComponent = useMemo(() => {
    return withOnDefocus(EditComponent, toggleOffEditing);
  }, [EditComponent, toggleOffEditing]);

  const [editValue, setEditValue] = useState<TValue | undefined>(value);

  useEffect(() => {
    if (!isEditingInternal) {
      setEditValue(value);
    }
  }, [value, isEditingInternal]);

  const editComponent = useMemo(() => {
    return (
      <DefocusableEditComponent
        options={options}
        value={editValue}
        viewSize={viewSize}
        onChange={onChange}
      />
    );
  }, [DefocusableEditComponent, editValue, options, onChange, viewSize]);

  const body = useMemo(() => {
    if (isEditingInternal) {
      return editComponent;
    }
    return viewComponent;
  }, [isEditingInternal, editComponent, viewComponent]);

  return <LoadingWrapperProvider>{body}</LoadingWrapperProvider>;
}

export const EditableWrapperBase = memo(
  EditableWrapperBaseUnmemoized,
  compareDeeply('link'),
) as typeof EditableWrapperBaseUnmemoized;
