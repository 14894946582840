import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { truncateIfTooLong } from '../../util/truncate';
import { useAuthMethodsDisplay } from './useAuthMethodsDisplay';
import { isCustomOAuthProvider } from '../../../functions/src/util/auth/isCustomOAuthProvider';
import { isFirebaseOAuthProvider } from '../../../functions/src/util/auth/isFirebaseOAuthProvider';
import { extractProviderId } from '../../../functions/src/util/auth/extractProviderIds';
import { useUnlinkDialog } from './useUnlinkDialog';
import Tooltip from '@mui/material/Tooltip';
import { useAuthProviders } from './useAuthProviders';
import { SignInMenuItemProps } from '../../components/header/SignInMenuItem';
import { decideLabelFromProvider } from '../../util/user/decideLabelFrom';

export const UNLINK_DIALOG_ID = 'UNLINK_DIALOG';

export const useMenuItemsUnlink = () => {
  const { uid } = useAuth();
  const { providersConnected = [] } = useAuthProviders();
  const authOptionsDisplay = useAuthMethodsDisplay();
  const { open: openUnlinkDialog } = useUnlinkDialog();
  const providersFiltered = useMemo(() => {
    return providersConnected.filter(({ providerId }) => {
      return providerId !== 'password' && providerId !== 'wallet';
    });
  }, [providersConnected]);
  const MenuItemsUnlink: SignInMenuItemProps[] = useMemo(() => {
    return providersFiltered.map(
      ({ providerId, email, displayName, phoneNumber, userId }) => {
        const isFirebaseProvider = isFirebaseOAuthProvider(providerId);
        const isCustomProvider = isCustomOAuthProvider(providerId);
        const { icon } =
          authOptionsDisplay[
            `${
              isFirebaseProvider || isCustomProvider
                ? extractProviderId(providerId)
                : 'phone'
            }`
          ];
        const uidFormatted = isCustomProvider
          ? `${userId}-${providerId}`
          : userId;
        const isMainProvider =
          uid === uidFormatted || providersConnected.length === 1;

        const label = decideLabelFromProvider({
          email,
          phoneNumber,
          displayName,
          providerId,
          userId,
        });

        const truncatedLabel = truncateIfTooLong(label);

        return {
          label: truncatedLabel,
          onClick: () => {
            if (providersConnected.length <= 1 || isMainProvider) {
              return;
            }
            return openUnlinkDialog({
              email,
              phoneNumber,
              userId,
              providerId,
              displayName,
            });
          },
          IconComponent: icon,
          Wrapper: isMainProvider ? (
            <Tooltip title="You cannot unlink your primary sign-in method.">
              <div></div>
            </Tooltip>
          ) : undefined,
        };
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openUnlinkDialog,
    providersConnected.length,
    providersFiltered.length,
    uid,
  ]);
  return { MenuItemsUnlink };
};
