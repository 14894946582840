import {
  forwardRef,
  useCallback,
  ComponentType,
  ForwardedRef,
  KeyboardEvent,
} from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { memo } from '../../util/memo';

export const DEFAULT_DEFOCUS_KEYS = ['Enter', 'Tab', 'Escape'] as const;

export type WithOnDefocusProps = {
  onKeyDown?: (event: KeyboardEvent) => void;
};

// eslint-disable-next-line @blumintinc/blumint/prefer-settings-object
export function withOnDefocus<TProps extends WithOnDefocusProps>(
  WrappedComponent: ComponentType<TProps>,
  onDefocus: () => void,
  keys: readonly string[] = DEFAULT_DEFOCUS_KEYS,
) {
  const WithOnDefocusUnmemoized = forwardRef(function WithOnDefocus(
    { onKeyDown, ...props }: TProps,
    ref: ForwardedRef<unknown>,
  ) {
    const interceptKeydown = useCallback(
      (event: React.KeyboardEvent) => {
        if (keys.includes(event.key)) {
          event.preventDefault();
          onDefocus();
        }
        onKeyDown?.(event);
      },
      [onKeyDown],
    );

    return (
      <ClickAwayListener onClickAway={onDefocus}>
        <div>
          <WrappedComponent
            // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
            {...(props as TProps)}
            ref={ref}
            onKeyDown={interceptKeydown}
          />
        </div>
      </ClickAwayListener>
    );
  });

  return memo(WithOnDefocusUnmemoized);
}
