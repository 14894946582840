/* eslint-disable @blumintinc/blumint/enforce-callback-memo */
import { LoadingButton } from '@mui/lab';
import { useCallback } from 'react';
import { Stack } from '@mui/material';
import { useRouter } from '../../hooks/routing/useRouter';
import { transformAttributesToActionButtonProps } from '../../util/notifications/transformAttributesToActionButtonProps';
import { useUpdateNotification } from '../../hooks/notification/useUpdateNotification';
import { useAuth } from '../../contexts/AuthContext';
import { memo } from 'src/util/memo';

export type ActionButtonProps = {
  id: string;
  attributes: Record<string, string>;
};

const NotificationActionButtonGroupUnmemoized = ({
  id,
  attributes,
}: ActionButtonProps) => {
  const router = useRouter();
  const { uidFull } = useAuth();
  const { markAsArchived } = useUpdateNotification({ toId: uidFull, id });
  const actionButtonProps = transformAttributesToActionButtonProps(attributes);

  const addQueryParams = useCallback(
    (href: string) => {
      router.push({
        pathname: window.location.pathname,
        query: {
          ...router.query,
          notif: id,
          'notif-action': href,
        },
      });
      markAsArchived();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.push, id, markAsArchived],
  );

  if (actionButtonProps.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {actionButtonProps.map(({ label, href }) => {
        return (
          <LoadingButton
            key={label}
            size="small"
            sx={{
              padding: '2px 8px',
              minHeight: 0,
              minWidth: 0,
            }}
            variant="text"
            onClick={() => {
              return addQueryParams(href);
            }}
          >
            {label}
          </LoadingButton>
        );
      })}
    </Stack>
  );
};

export const NotificationActionButtonGroup = memo(
  NotificationActionButtonGroupUnmemoized,
);
