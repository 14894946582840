import { Team } from '../../types/firestore/Game/Tournament/Team';

export const isAcceptedOrPendingTeam = (
  userId: string,
  teams: Team<Date>[],
) => {
  return teams.some((team) => {
    const { userIds: memberIds, members } = team;

    return (
      memberIds.includes(userId) ||
      members.some(({ userId: memberId, status }) => {
        return memberId === userId && status !== 'declined';
      })
    );
  });
};
