/* eslint-disable max-lines */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useState, useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { AlgoliaLayout } from '../algolia/AlgoliaLayout';
import { UserCardAdd } from '../cards/friend/UserCardAdd';
import { FriendCarouselHeader } from '../social-drawer/friends/FriendCarouselHeader';
import { ChannelGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup';
import {
  // useUserVerticalCarouselAds,
  UserVerticalCarouselProps,
  UserVerticalCarousel,
} from '../algolia/catalog-wrappers/UserVerticalCarousel';
import { memo } from '../../util/memo';
import { usePersonalChannelGroup } from '../../hooks/messaging/usePersonalChannelGroup';
import { useRouterState } from '../../hooks/routing/useRouterState';
import { AlertStandard } from '../AlertStandard';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import {
  FRIEND_TABS_HEIGHT_DESKTOP,
  FRIEND_TABS_HEIGHT_MOBILE,
} from '../social-drawer/friends/FriendsAdd';
// import { withoutUser } from '../../util/algolia/withoutUser';
import { USER_CONFIGURE_OPTIONS } from '../../../functions/src/util/algolia/config/user';
// import { CHANNEL_MANAGER_AD } from '../../../functions/src/util/ads/adIds';
// import { useMobile } from '../../hooks/useMobile';
import { FriendPaneOption } from '../social-drawer/friends/FriendsPane';
import { MemberList } from './MemberList';
import { ChannelMembersProvider } from './ChannelMembersContext';
import { useAuth } from 'src/contexts/AuthContext';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { LoadingButton } from 'src/components/buttons/LoadingButton';

export const GROUP_CHAT_MAX = 100 as const;
export const CREATE_BUTTON_HEIGHT = 42 as const;
export const NAME_INPUT_HEIGHT = 154 as const;

export type ChannelManagerProps = {
  initialName?: string;
};

const ChannelManagerUnmemoized = ({ initialName }: ChannelManagerProps) => {
  const { userData } = useAuth();
  const theme = useTheme();
  const [name, setName] = useState(initialName || '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();
  const [__, setFriendsTab] = useRouterState({
    key: 'friends',
  });

  const initializeMembersWithSelf = useCallback(() => {
    if (!userData) return [];

    return [
      {
        userId: userData.id,
        image: userData.imgUrl,
        username: userData.username,
        isAdmin: true,
      },
    ];
  }, [userData]);

  // eslint-disable-next-line react/hook-use-state
  const membersState = useState<ChannelGroupUser[]>(initializeMembersWithSelf);
  const [members, setMembers] = membersState;

  const createChannelGroup = useCallback(async () => {
    if (members.length > 2 && !name) {
      setErrorMessage('Please add a name.');
      return;
    }
    const memberIds = members.reduce((prev, { userId }) => {
      if (userId && userId !== userData?.id) {
        prev.push(userId);
      }
      return prev;
    }, [] as string[]);

    const { id } = await setPersonalChannelGroup({
      type: members.length === 2 ? 'dm' : 'personalGroup',
      friendIds: memberIds,
      title: name,
    });

    setFriendsTab('View' as FriendPaneOption);
    openChannelGroup({ channelGroupId: id });
  }, [
    members,
    name,
    openChannelGroup,
    setPersonalChannelGroup,
    setFriendsTab,
    userData?.id,
  ]);

  const maxMembersReached = members.length >= GROUP_CHAT_MAX;

  // const UserVerticalCarouselAds =
  //   useUserVerticalCarouselAds(CHANNEL_MANAGER_AD);

  // const isMobile = useMobile();
  // const AllUserVerticalCarousel = useMemo(() => {
  //   return withoutUser(
  //     isMobile ? UserVerticalCarousel : UserVerticalCarouselAds,
  //   );
  // }, [UserVerticalCarouselAds, isMobile]);

  const UserCarouselWrapper = useCallback(
    (props: Omit<UserVerticalCarouselProps, 'ContentCard' | 'header'>) => {
      return (
        <UserVerticalCarousel
          // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
          ContentCard={(userAlgolia) => {
            return (
              <UserCardAdd {...userAlgolia} disabled={maxMembersReached} />
            );
          }}
          {...props}
          header={<FriendCarouselHeader separateSearch title="ADD USERS" />}
        />
      );
    },
    [maxMembersReached],
  );

  const removeFromList = useCallback(
    (toRemoveId: string) => {
      setMembers((prev) => {
        return prev.filter(({ userId: memberId }) => {
          return memberId !== toRemoveId;
        });
      });
      setErrorMessage('');
      setName('');
    },
    [setMembers],
  );

  const alert = useMemo(() => {
    return (
      maxMembersReached && (
        <AlertStandard
          message={'Maximum number of members reached.'}
          severity="warning"
        />
      )
    );
  }, [maxMembersReached]);

  const setMemberName = useCallback((e) => {
    setErrorMessage('');
    setName(e.target.value);
  }, []);

  const chatNameInput = useMemo(() => {
    return (
      members.length > 2 && (
        <Stack alignItems="flex-start" direction="column" spacing={2}>
          <GradientTypography gradientColor={'primary.vertical'} variant="h6">
            NAME CHAT
          </GradientTypography>
          <TextField
            autoFocus
            error={!!errorMessage}
            fullWidth
            helperText={errorMessage}
            sx={{
              '.MuiInputBase-root': { height: `${CREATE_BUTTON_HEIGHT}px` },
            }}
            value={name}
            onChange={setMemberName}
          />
        </Stack>
      )
    );
  }, [members.length, errorMessage, name, setMemberName]);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          md: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        p: 4,
        pt: 2,
      }}
    >
      <Stack height={`calc(100% - ${CREATE_BUTTON_HEIGHT}px)`} spacing={6}>
        <Box sx={{ maxHeight: '50%' }}>
          <ChannelMembersProvider membersState={membersState}>
            <AlgoliaLayout
              CatalogWrapper={UserCarouselWrapper}
              configureOptions={USER_CONFIGURE_OPTIONS}
              index="CONTENT"
            />
          </ChannelMembersProvider>
        </Box>
        {alert}
        <Stack
          alignItems="flex-start"
          direction="column"
          spacing={2}
          sx={{ maxHeight: `calc(50% - ${NAME_INPUT_HEIGHT}px)` }}
        >
          <GradientTypography gradientColor="primary.vertical" variant="h6">
            MEMBERS
          </GradientTypography>
          <Box
            sx={{
              overflowY: 'auto',
              ...theme.scrollbars.invisible,
            }}
            width="100%"
          >
            <MemberList members={members} onRemove={removeFromList} />
          </Box>
        </Stack>
        {chatNameInput}
      </Stack>
      <LoadingButton
        color="primary"
        disabled={members.length < 2 || (members.length > 2 && !name)}
        fullWidth
        sx={{ height: `${CREATE_BUTTON_HEIGHT}px` }}
        variant="contained"
        onClick={createChannelGroup}
      >
        Create
      </LoadingButton>
    </Stack>
  );
};

export const ChannelManager = memo(ChannelManagerUnmemoized);
