import { ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { UserCardLayout, UserCardLayoutProps } from '../user/UserCardLayout';
import { useFriendCardStatus } from '../../../hooks/friends/useFriendCardStatus';

const DEFAULT_SX = {} as const;
const DEFAULT_AVATAR_PROPS = {
  shouldShowStatus: true,
  badgeSx: {
    '.MuiBadge-badge': {
      height: '16px',
      width: '16px',
    },
  },
} as const;

export type FriendCardLayoutProps = Omit<UserCardLayoutProps, 'avatarProps'> & {
  mutualFriendsCountEstimate?: number;
  children?: ReactNode;
  sx?: SxProps;
};

const FriendCardLayoutUnmemoized = ({
  children,
  status,
  mutualFriendsCountEstimate,
  id,
  sx = DEFAULT_SX,
  ...props
}: FriendCardLayoutProps) => {
  const statusResolved = useFriendCardStatus({
    status,
    mutualFriendsCount: mutualFriendsCountEstimate,
    id,
  });

  return (
    <UserCardLayout
      avatarProps={DEFAULT_AVATAR_PROPS}
      id={id}
      status={statusResolved}
      sx={sx}
      {...props}
    >
      {children}
    </UserCardLayout>
  );
};

export const FriendCardLayout = memo(FriendCardLayoutUnmemoized);
