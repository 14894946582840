import { Screenshot } from '../../../types/firestore/Game/Tournament/Round/Match/Result';
import { findCaseInsensitive } from '../../findCaseInsensitive';
import { Loadable } from '../../isLoading';
import { assertSafe } from '../../assertSafe';
import { findTeamIndex } from './findTeamIndex';

export type IsScreenshotUploadedProps = {
  sessionIndex: number;
  teamUserIds: string[][];
  screenshots?: Screenshot[];
  uid?: Loadable<string>;
};

export const isScreenshotUploaded = ({
  sessionIndex,
  teamUserIds,
  screenshots,
  uid,
}: IsScreenshotUploadedProps) => {
  const teamIndex = findTeamIndex(teamUserIds, uid);
  return (
    !!screenshots &&
    screenshots?.some(
      ({
        sessionNumber: sessionIndexScreenshot,
        uploader: { id: uploaderUid },
      }) => {
        const acceptedUserIds = teamUserIds[assertSafe(Number(teamIndex))];
        const hasTeamUploaded = findCaseInsensitive(
          uploaderUid,
          acceptedUserIds,
        );
        return !!hasTeamUploaded && sessionIndexScreenshot === sessionIndex;
      },
    )
  );
};
