import { UserProviderInfo } from '../../../functions/src/types/firestore/User';
import { isFirebaseOAuthProvider } from '../../../functions/src/util/auth/isFirebaseOAuthProvider';

export const decideLabelFromProvider = ({
  providerId,
  email,
  phoneNumber,
  displayName,
  uid,
}: Omit<UserProviderInfo, 'photoURL'>) => {
  const isFirebaseProvider = isFirebaseOAuthProvider(providerId);
  const isPhoneProvider =
    providerId.toLocaleLowerCase().includes('phone') && !!phoneNumber;
  if (isFirebaseProvider) {
    return email;
  }
  if (isPhoneProvider) {
    return phoneNumber;
  }
  return displayName || email || uid;
};
