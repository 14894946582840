import AvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { SxProps } from '@mui/material/styles';
import { useCallback, Fragment } from 'react';
import { memo } from '../../util/memo';
import { AvatarGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { adjustSize } from '../../util/styles/adjustSize';
import { AvatarSurplus } from './AvatarSurplus';
import { AvatarStatusProps } from './AvatarStatus';
import { Avatars } from './Avatars';

export const AVATAR_GROUP_MAX = 5 as const;

export type AvatarGroupNextProps = Omit<AvatarGroupProps, 'sx'> &
  Omit<AvatarStatusProps, 'imgUrl' | 'onlineStatus'> & {
    users: AvatarGroupUser[];
    borderColor?: string;
    surplusSx?: SxProps;
    sx?: SxProps;
  };

const AvatarGroupNextUnmemoized = (props: AvatarGroupNextProps) => {
  const {
    users,
    borderColor,
    shouldShowStatus = false,
    size,
    avatarSx = {},
    sx = {},
    surplusSx = {},
    badgeSx = {},
    ...rest
  } = props;

  const Surplus = useCallback(
    (surplus: number) => {
      return (
        <AvatarSurplus
          avatarCount={users.length}
          surplus={surplus}
          sx={{
            ...surplusSx,
            fontSize: adjustSize(size, (n: number) => {
              return n / 1.5;
            }),
          }}
        />
      );
    },
    [surplusSx, users.length, size],
  );

  if (users.length > 1) {
    return (
      <AvatarGroup
        {...rest}
        renderSurplus={Surplus}
        sx={{
          ...sx,
          '.MuiAvatar-root': {
            height: size,
            width: size,
            ...sx['.MuiAvatar-root'],
          },
        }}
      >
        <Avatars
          avatarSx={avatarSx}
          badgeSx={badgeSx}
          borderColor={borderColor}
          shouldShowStatus={shouldShowStatus}
          size={size}
          users={users}
        />
      </AvatarGroup>
    );
  }
  return (
    <Fragment>
      <Avatars
        avatarSx={avatarSx}
        badgeSx={badgeSx}
        borderColor={borderColor}
        shouldShowStatus={shouldShowStatus}
        size={size}
        users={users}
      />
    </Fragment>
  );
};
export const AvatarGroupNext = memo(AvatarGroupNextUnmemoized);
